<template>
  <v-container>

    <v-data-table :custom-filter="filterGrid" :headers="headersLijst" dense :items="deelnemers" :search="search">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Overzicht deelnemers</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Zoek in grid" class="mx-4"></v-text-field>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <div v-if="hasAudit">
                <v-btn icon>
                  <v-icon medium v-on="on" @click="newLijst">mdi-plus-circle</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ itemModel.email }}</span>
              </v-card-title>

              <v-card-text>
                <v-form @submit.prevent="" v-model="valid">
                  <v-text-field v-model="itemModel.email" :rules="[rules.required]" label="Email" required
                    autofocus></v-text-field>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Annuleren</v-btn>
                <v-btn color="blue darken-1" :disabled="!valid" @click="addDeelnemer()">Opslaan</v-btn>
              </v-card-actions>
              <v-container>
                <v-alert outlined type="error" v-for="(err, index) in errs" :key="index">
                  {{ err.msg }}</v-alert>
              </v-container>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>


      <template v-slot:[`item.opties`]="{ item }">
        <v-menu dense>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" text dark v-bind="attrs" v-on="on">
              mdi-menu
            </v-icon>
          </template>

          <v-list>
            <v-list-item @click="openOptionsDialog(i, item)" v-for="(i) in getOptions(item)" :key="i.id">
              <v-list-item-title>{{ i.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.datum_registratie`]="{ item }">
        {{ formatDate(item.datum_registratie) }}
      </template>

      <template v-slot:[`item.datum_ingediend`]="{ item }">
        {{ formatDate(item.datum_ingediend) }}
      </template>

      <template v-slot:[`item.aanpassen`]="{ item }">
        <router-link v-if="hasAudit && (item.datum_ingediend == null)"
          :to="{ name: 'vragen', params: { lijstId: item.id } }">
          Naar vragenlijst
        </router-link>
      </template>

      <template v-slot:[`item.audit`]="{ item }">
        <v-icon v-if="item.datum_ingediend" medium @click="genereerBestand(item.id)">mdi-file-outline</v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogOpties" max-width="500px">
      <v-card v-if="selectedOption">
        <v-card-title>
          <span class="headline">{{ itemModel.email }}</span>
        </v-card-title>
        <v-card-subtitle>
          {{ selectedOption.text }}
        </v-card-subtitle>

        <v-card-text>
          <div v-if="selectedOption.id == 1">
            <p>De deelnemer heeft de audit al ingediend en kan deze niet meer aanpassen. Door de lijst open te zetten
              kan
              de deelnemer weer bij de ingevulde audit en kan deze ook aanpassen</p>
            <v-btn block v-if="itemModel.datum_ingediend != null" @click="lijstOpen()">
              Lijst weer open zetten
            </v-btn>
          </div>

          <div v-else-if="selectedOption.id == 2">
            <p>De geselecteerde persoon wordt verwijderd uit de deelnemerslijst. Alle reeds ingevulde gegevens worden
              verwijderd</p>
            <v-form>
              <v-text-field v-model="checkVerwijder" label="herhaal email adres"></v-text-field>
              <v-btn :disabled="!isValid(checkVerwijder, itemModel)" block @click="deelnemerVerwijderen()">
                Verwijder deelnemer
              </v-btn>
            </v-form>
          </div>

          <div v-else-if="selectedOption.id == 3">
            <p>De deelnemer moet zich opnieuw registeren. Het wachtwoord wordt hierdoor gereset</p>
            <v-form>
              <v-text-field v-model="checkOpnieuw" label="herhaal email adres"></v-text-field>
              <v-btn :disabled="!isValid(checkOpnieuw, itemModel)" block @click="opnieuwRegistreren()">
                Opnieuw laten registreren
              </v-btn>
            </v-form>
          </div>

          <div v-else-if="selectedOption.id == 4">
            <p>Klik op de onderstaande button om een uitnodiging te sturen naar de deelnemer</p>
            <v-btn block @click="uitnodigingVersturen()">
              Uitnodiging versturen
            </v-btn>

          </div>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogOpties = false">Annuleren</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSpinner" max-width="350px">
      <div class="text-center">
        <v-card>
          <v-card-title class="justify-center">Document wordt gegenereerd</v-card-title>
          <v-card-text>
            <v-progress-circular indeterminate color="primary" :size="70" :width="7"></v-progress-circular>
          </v-card-text>
        </v-card>
      </div>

    </v-dialog>

  </v-container>
</template>

<script>
import client from "api-client";
import { format } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "AdminDeelnemers",
  data: () => ({
    selectedOption: null,
    search: "",
    valid: false,
    dialog: false,
    dialogOpties: false,
    dialogSpinner: false,
    errs: [],
    itemModel: {},
    deelnemers: [],
    rules: {
      required: (value) => !!value || "Verplicht.",
    },
    selectedLijst: [],
    headersLijst: [
      { text: "", value: "opties", sortable: false },
      { text: "Email", value: "email", sortable: true },
      { text: "Geregistreerd op", value: "datum_registratie", sortable: true },
      { text: "Inhoud", value: "has_content", sortable: true },
      { text: "Ingediend op", value: "datum_ingediend", sortable: true },
      { text: "", value: "aanpassen", sortable: false },
      { text: "", value: "audit", sortable: true }
    ],

    checkVerwijder: "",
    checkOpnieuw: ""

  }),
  methods: {
    filterGrid(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
    openOptionsDialog(i, item) {
      this.selectedOption = i
      this.itemModel = { ...item }
      this.dialogOpties = true
    },
    formatDate(date) {
      if (date != null) return format(new Date(date), "dd-MM-yyyy");
    },
    newLijst() {
      this.itemModel = {};
      this.errs = [];
    },

    isValid(val1, val2) {
      return val1?.toLowerCase() == val2?.email?.toLowerCase()
    },


    deelnemerVerwijderen() {

      client
        .verwijderDeelnemer(this.$route.params.instantieId, this.$route.params.auditId, {
          email: this.checkVerwijder.toLowerCase(),
        })
        .then(() => {
          this.itemModel = {};
          this.getDeelnemers(this.$route.params.instantieId, this.$route.params.auditId);
          this.dialogOpties = false;
          this.checkVerwijder = null;
        });
    },
    lijstOpen() {
      if (this.itemModel.datum_ingediend != null) {
        client
          .openLijst({
            id: this.itemModel.id,
          })
          .then(() => {
            this.itemModel = {};
            this.getDeelnemers(this.$route.params.instantieId, this.$route.params.auditId);
            this.dialogOpties = false;
          });
      }
    },
    getDeelnemers(instantieId, auditId) {
      client.fetchDeelnemersInstantieAudit(instantieId, auditId).then((data) => {
        this.deelnemers = data;
      });
    },
    opnieuwRegistreren() {
      client.verwijderGebruiker({
        email: this.checkOpnieuw.toLowerCase()
      }).then(() => {
        this.getDeelnemers(this.$route.params.instantieId, this.$route.params.auditId);
        this.dialogOpties = false
        this.checkOpnieuw = ""
      });
    },

    addDeelnemer() {
      client
        .addDeelnemer(this.$route.params.instantieId, this.$route.params.auditId, {
          email: this.itemModel.email
        })
        .then(() => {
          this.getDeelnemers(this.$route.params.instantieId, this.$route.params.auditId);
          this.dialog = false;
        }).catch((errs) => {
          this.errs = errs
        })
    },
    getOptions(item) {
      let options = []

      if (item.datum_ingediend != null)
        options.push({ id: 1, text: 'Open zetten' })
      else
        options.push({ id: 2, text: 'Verwijderen' })


      options.push({ id: 3, text: 'Opnieuw laten registreren' })
      options.push({ id: 4, text: 'Uitnodiging versturen' })

      return options

    },
    genereerBestand(lijstGuid) {
      this.dialogSpinner = true
      client.downloadLijst(lijstGuid).then((response) => {

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${lijstGuid}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.dialogSpinner = false

      }).catch(() => {
        this.dialogSpinner = false
      });
    },
    onAanpassen(item) {
      this.itemModel = { ...item };
      this.dialogOpties = true;
    },
    showOptionsButton(item) {
      return item.has_content == false || item.datum_ingediend != null;
    },
    uitnodigingVersturen() {
      client.mailInvite(this.itemModel).then(() => {
        alert('uitnodiging is verstuurd')
      });
    }
  },
  watch: {
    "$route.params.instantieId": {
      handler: function (instantieId) {
        this.getDeelnemers(instantieId, this.$route.params.auditId);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      gebruiker: "gebruiker",
    }),
    instantie: function () {
      return this.$store.getters.getInstantie(this.$route.params.instantieId)
    },
    hasAudit: function () {
      return (this.instantie?.hasAudit || this.gebruiker.has_admin)
    },
    hasUserAdmin: function () {
      return (this.instantie?.hasUserAdmin || this.gebruiker.has_admin)
    }


  },
};
</script>